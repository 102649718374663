<template>
    <div class="stu-train-index">
        <div class="stu-train-header">
            <div class="stu-header-process">
                <span class="process-text">
                    {{page}}/{{total}}训练
                </span>
            </div>
            <btn-process :page="page" :total="total" @change="toChange"></btn-process>
            <div class="stu-header-timestring">
                <span class="time-txt"><i class="el-icon-time"></i>距离训练结束：</span><time-count-down :left-time="leftTime" @change="updateTime"></time-count-down>
            </div>
            <div class="stu-avatar-info">
                <el-avatar :src="avatar" class="avatar" :size="40"></el-avatar>
                <span class="username">{{username}}</span>
                <div class="to-index" @click="toIndex"><i class="iconfont">&#xe698;</i></div>
            </div>
        </div>
        <div class="stu-train-main">
            <theory-topics-detail height="100%" :key="keyBox"></theory-topics-detail>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common";
import TheoryTopicDetail from "@/views/admin/theoryTopic/theoryTopicDetail";
import TheoryTopicsDetail from "@/views/student/trainManage/theoryTopicsDetail";
import {competitionInfo, stuLogout} from "@/utils/apis";
import BtnProcess from "@/components/topicComponents/btnProcess";
import TimeCountDown from "@/components/plugs/timeCountDown";

export default {
    name: "studentTheoryIndex",
    components: {TimeCountDown, BtnProcess, TheoryTopicsDetail, TheoryTopicDetail},
    data() {
        return {
            leftTime: 0,
            timeString: '',
            avatar: localStorage.getItem('headerImg') ? localStorage.getItem('headerImg') : require('@/assets/images/user_img.png'),
            username: localStorage.getItem('username') ? localStorage.getItem('username') : '游客',
            total: this.$route.query.total ? Number(this.$route.query.total) : 0,
            page: this.$route.query.page ? Number(this.$route.query.page) : 0,
            keyBox: 0,
        }
    },
    created() {
        this.countTime();
    },
    methods: {
        updateTime(val) {
            this.leftTime = Number(val);
            if (this.leftTime < 0) {
                this.$message({
                    type: "error",
                    message: "训练时间结束",
                    duration: 3000,
                    onClose: () => {
                        localStorage.clear();
                        this.$router.push("/");
                    }
                });
            }
        },
        toChange(val) {
            let query = this.$route.query;
            let times_id = query.times_id;
            let id = query.id;
            let total = Number(query.total);
            if (val && this.page < total) {
                this.page++;
            } else if (!val && this.page > 1) {
                this.page--;
            }
            this.$router.push({
                path: '/student/theorytrain/index',
                query: {
                    page: this.page,
                    times_id: times_id,
                    id: id,
                    total: total
                }
            });
            this.keyBox++;
        },
        toIndex() {
            stuLogout().then(res => {
                if (res.code) {
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        onClose: () => {
                            localStorage.clear();
                            this.$router.push('/');
                        }
                    });
                } else {
                  this.$router.push('/');
                }

            }).catch(err => {
                console.log(err);
            })
        },
        countTime() {
            let now_time = Date.parse(new Date());
            competitionInfo().then(res => {
                if (res.data.train) {
                    let response_time = Date.parse(new Date());
                    let current_time = (response_time - now_time) / 1000;
                    let newTime = Number(res.data.train.now) - current_time;
                    this.leftTime = Number(res.data.train.end_time) - newTime;
                }
            }).catch(err => {
                console.log('err', err);
            })

        }

    }

}
</script>

<style scoped lang="scss">
.stu-train-header {
    background-color: #FFF;
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .stu-header-process {
        margin-left: 39px;
        .process-text {
            color: #1122D8;
            font-size: 24px;
        }
    }
    .stu-header-timestring {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &::v-deep span {
            color: #FF0101;
            font-size: 20px;
            i {
                margin-right: 10px;
            }
        }
    }
    .stu-avatar-info {
        width: 200px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .avatar {
            margin-right: 13px;
        }
        .username {
            color: #333333;
            font-size: 16px;
            margin-right: 15px;
        }
        .to-index {
            cursor: pointer;
            i.iconfont {
                font-size: 20px;
                color: #AAA;
            }
        }
    }
}
.stu-train-main {
    width: 100%;
    height: calc(100vh - 60px);
    &::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}

</style>